import axios from "../http/axios";

export default {
  pendencias: (payload) => {
    return axios
      .get(
        `/v1/minhas-solicitacoes?pagina=${payload.pagina}&tamPagina=${50}&status=${payload.status}`,
      )
      .then((res) => {
        return res.data;
      });
  },
  pagamentos: (payload) => {
    return axios
      .get(
        `/v1/meus-pagamentos?pagina=${payload.pagina}&status=${payload.status}&usuario=${payload.usuario}`,
      )
      .then((res) => {
        return res.data;
      });
  },
  finalizar: (codigo) => {
    return axios
      .post(`/v1/interacao-assinatura/finalizar/${codigo}`, {})
      .then((res) => {
        return res.data;
      });
  },
  list: (payload) => {
    return axios
      .get(
        `/v1/minhas-solicitacoes?pagina=${payload.pagina}&status=${payload.status}`,
      )
      .then((res) => {
        return res.data;
      });
  },
  findSolicitacao: (payload) => {
    return axios.post(`/v1/solicitacao/processo`, payload).then((res) => {
      return res.data;
    });
  },
  consultarDocumento: (payload) => {
    return axios.post(`/v1/documentos/conferir`, payload).then((res) => {
      return res.data;
    });
  },
};
