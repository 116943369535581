import { createAction, handleActions } from "redux-actions";

// ACTION TYPES
const USER_LOGIN = `${process.env.PUBLIC_URL}/USER/LOGIN`;
const USER_LOGIN_GOVBR = `${process.env.PUBLIC_URL}/USER/GOVBR`;
const USER_LOGOUT = `${process.env.PUBLIC_URL}/USER/LOGOUT`;
const USER_SIMPLE_LOGOUT = `${process.env.PUBLIC_URL}/USER/SIMPLE_LOGOUT`;
const USER_FIND = `${process.env.PUBLIC_URL}/USER/FIND`;
const USER_PASSWORD_CHANGE = `${process.env.PUBLIC_URL}/USER/PASSWORD/CHANGE`;
const USER_UPDATE_STORE = `${process.env.PUBLIC_URL}/USER/UPDATE_STORE`;
const USER_REMOVE_STORE = `${process.env.PUBLIC_URL}/USER/REMOVE_STORE`;
const USER_FORCE_LOGOUT_STORE = `${process.env.PUBLIC_URL}/USER/USER_FORCE_LOGOUT_STORE`;
const USER_CHANGE = `${process.env.PULIC_URL}/USER/USER_CHANGE`;
const USER_DADOS_EDITAR_CADASTRO = `${process.env.PUBLIC_URL}/USER/DADOS_EDITAR_CADASTRO`;
const USER_EDITAR_CADASTRO = `${process.env.PUBLIC_URL}/USER/EDITAR_CADASTRO`;
const USER_READ_MODAL_PROFILES = `${process.env.PUBLIC_URL}/USER/READ_MODAL_PROFILES`;

export const types = {
  USER_LOGIN,
  USER_LOGIN_GOVBR,
  USER_LOGOUT,
  USER_SIMPLE_LOGOUT,
  USER_FIND,
  USER_PASSWORD_CHANGE,
  USER_UPDATE_STORE,
  USER_REMOVE_STORE,
  USER_CHANGE,
  USER_DADOS_EDITAR_CADASTRO,
  USER_EDITAR_CADASTRO,
  USER_READ_MODAL_PROFILES,
};

// ACTIONS
const setUserReadModalProfiles = createAction(USER_READ_MODAL_PROFILES);
const loginUser = createAction(USER_LOGIN);
const forceLoginUser = createAction(USER_LOGIN_GOVBR, undefined, () => ({
  force: true,
}));
const simpleLogoutUser = createAction(USER_SIMPLE_LOGOUT);
const logoutUser = createAction(USER_LOGOUT);
const forceLogoutUser = createAction(USER_LOGOUT, undefined, () => ({
  force: true,
}));
const changeUserPassword = createAction(USER_PASSWORD_CHANGE);
const findUser = createAction(USER_FIND);
const updateUserStore = createAction(USER_UPDATE_STORE);
const removeUserStore = createAction(USER_REMOVE_STORE);
const userForceLogoutStore = createAction(USER_FORCE_LOGOUT_STORE);
const changeUserActive = createAction(USER_CHANGE);
const buscarDadosParaEditarCadastro = createAction(USER_DADOS_EDITAR_CADASTRO);
const editarCadastro = createAction(USER_EDITAR_CADASTRO);

export const actions = {
  loginUser,
  forceLoginUser,
  logoutUser,
  simpleLogoutUser,
  forceLogoutUser,
  changeUserPassword,
  findUser,
  updateUserStore,
  removeUserStore,
  userForceLogoutStore,
  changeUserActive,
  buscarDadosParaEditarCadastro,
  editarCadastro,
  setUserReadModalProfiles,
};

// INITIAL STATE
const initialState = {};

// REDUCERS
const reducer = handleActions(
  {
    [USER_UPDATE_STORE]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    [USER_REMOVE_STORE]: () => initialState,
    [USER_FORCE_LOGOUT_STORE]: () => ({forceLogout:true}),
    [USER_READ_MODAL_PROFILES]: (state) => ({
      ...state,
      readModalProfiles: true,
    }),
  },
  initialState,
);

export default reducer;

// SELECTORS
const getUser = (state) => state.usuario;
const isAuthenticated = (state) => Boolean(state.usuario.authenticated);
const isForcedLogout = (state) => !Boolean(state.usuario.authenticated) && state.usuario.forceLogout ? true : false;
const getUserRoles = (state) => state.usuario.authorities || [];
const isUserInRole = (state, role) =>
  getUserRoles(state).some((r) => r === role);
const isLoginGov = (state) => state.usuario.tpLogin === "govbr";
const isLoginAuth = (state) => state.usuario.tpLogin === "auth";
const getUserReadModalProfiles = (state) => state.usuario.readModalProfiles || false;

const isCadIncompleto = (state) => {
  const activeProfile = state.usuario.perfils
    ? state.usuario.perfils.find((p) => p.active === true)
    : null;

    return activeProfile ? activeProfile.flCadCompletoGovBr === 'N' : false;;
}
const getUserActive = (state) =>
  state.usuario.perfils
    ? state.usuario.perfils.find((p) => p.active === true)
    : {};

const getAccessToken = (state) => {
  const activeProfile = state.usuario.perfils
    ? state.usuario.perfils.find((p) => p.active === true)
    : null;

  return activeProfile ? activeProfile.token : null;
}
const getSessionId = (state) => {
  const activeProfile = state.usuario.perfils
    ? state.usuario.perfils.find((p) => p.active === true)
    : null;

  return activeProfile ? activeProfile.sessionId : null;
}

export const selectors = {
  getUser,
  isAuthenticated,
  isForcedLogout,
  getAccessToken,
  getSessionId,
  getUserRoles,
  isLoginGov,
  isLoginAuth,
  isCadIncompleto,
  isUserInRole,
  getUserActive,
  getUserReadModalProfiles,
};
