import React, { useState } from "react";
import "./modal-perfils-styles.css";
// import { FormattedHTMLMessage, FormattedMessage, injectIntl } from "react-intl";
// import { compose } from "redux";
import { Button, Modal, Select } from "@material-ui/core";

const ModalPerfils = ({ modalOpen, perfils, userActive, alterarPerfil }) => {
    //   const { intl } = props;
    const [perfilSelecionado, setPerfilSelecionado] = useState(userActive.id);

    const callbackContinuar = () => {
        alterarPerfil(perfilSelecionado);
    }

    return (
        <Modal open={modalOpen}>
            <div className="modal-perfils">
                <h2 className="title">Perfil de Acesso</h2>
                <div className="content">
                    <Select
                        native
                        label="Selecione o perfil"
                        className="select-perfil"
                        inputProps={{
                            name: 'perfil',
                            id: 'perfil-select',
                        }}
                        defaultValue={perfilSelecionado}
                        onChange={(e) => setPerfilSelecionado(e.target.value)}
                    >
                        {perfils.map(perfil => (
                            <option
                                key={perfil.id}
                                value={perfil.id}
                            >
                                {perfil.identificacao} - {perfil.nome}
                            </option>
                        ))}
                    </Select>
                    <div className="info">
                        <p>
                            Identificamos Empresa(s) vinculada(s) ao seu CPF no GovBR.
                            <br/>
                            Seu perfil CPF é carregado por padrão, porém, você pode alternar para pessoa Jurídica.
                        </p>
                        <p>Durante sua navegação, essa opção permanecerá disponível no canto superior direito deste Portal de Atendimento.</p>
                    </div>
                    <Button variant="contained" color="primary" onClick={callbackContinuar}>
                        {/* <FormattedMessage id="LABELS.COMMONS.MENU.MINHAS_PENDENCIAS" /> */}
                        Continuar
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ModalPerfils;
