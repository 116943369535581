/* eslint-disable no-unused-vars */
/* eslint-disable no-class-assign */
/* eslint-disable jsx-a11y/anchor-is-valid */
import classnames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent} from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { HighContrast } from "../../../presentation/components/accessibility";

import {
  actions as userActions,
  selectors as userSelectors,
} from "../../../redux/modules/usuario";

import {
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  withStyles,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import connect from "react-redux/lib/connect/connect";
import { ROUTES } from "../../../commons/routes/routes";
import LanguageMenu from "../../../presentation/components/language-menu/language-menu";
import MainLogo from "../../../presentation/components/logo/main-logo/main-logo";
import MenuIcon from "../../../presentation/components/menu-icon/menu-icon";
import "./styles/app-tool-bar-styles.css";

class AppToolbar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      selectedIndex: 0,
      headerTitleDisplay: 'block'
    };
  }

  componentDidMount() {
    if (this.props.isAuthenticated) {
      for (let index = 0; index < this.props.perfils.length; index++) {
        if (this.props.perfils[index].id === this.props.userActive.id) {
          this.setState({ selectedIndex: index });
        }
      }
    }
    this.updateHeaderParamns();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.headerTitleDisplay !== this.state.headerTitleDisplay) {
      this.updateHeaderParamns();
    }
    if (prevProps.userActive.id !== this.props.userActive.id) {
      this.setState({ selectedIndex: this.props.perfils.findIndex((p) => p.id === this.props.userActive.id) });
    }
  }

  updateHeaderParamns() {
    document.documentElement.style.setProperty('--header-title-display', this.state.headerTitleDisplay);
  }

  handleHeaderParamsChange = (headerTitleDisplay) => {
    this.setState({ headerTitleDisplay });
  };

  renderMenuButton() {
    const { open, intl } = this.props;
    this.handleHeaderParamsChange(intl.formatMessage({ id: "LABELS.COMMONS.PORTAL.HEADER_TITLE_DISPLAY" }));

    return (
      <a
        className={classnames("sds-topbar__menu-btn", {
          "sds-topbar__menu-btn--active": open,
        })}
        onClick={this.props.menuToggle}
        aria-label={open === true ? "Recolher Menu" : "Expandir Menu"}
        aria-expanded={open === true ? "true" : "false"}
        role="button"
      >
        <MenuIcon open={open} />
      </a>
    );
  }

  render() {
    const { renderMenuBool, isAuthenticated, perfils, classes, isCadIncompleto } = this.props;

    const handleMenuItemClick = (event, index, perfilId) => {
      this.setState({ anchorEl: null, selectedIndex: index });
      this.props.changeUserActive({
        perfilId: perfilId,
        perfils: perfils,
      });
    };

    const handleClickListItem = (event) => {
      this.setState({ anchorEl: event.currentTarget });
    };

    const handleClose = (event) => {
      this.setState({ anchorEl: null });
    };

    return (
      <header className={classnames("sds-topbar")}>
        {/* TODO adicionar string ao BD */}
        <a
          id="skip-to-content"
          className="app-tool-bar-skip-to-content"
          href="#main-content"
          role="button"
        >
          Vá para o conteúdo
        </a>

        {renderMenuBool && this.renderMenuButton()}
        {renderMenuBool && (
          <Link to={isCadIncompleto ? (e) => e.preventDefault() : ROUTES.ONBOARDING.path} className="sds-topbar__logo">
            <MainLogo intl={this.props.intl} />
          </Link>
        )}
        {!renderMenuBool && (
          <img
            className="sds-topbar__logo"
            src={`/externo-portal-backend/configuracoes/frontend/download/logo.cliente`}
            alt=""
          />
        )}
        <h1>
          <FormattedMessage id="LABELS.COMMONS.PORTAL.HEADER" />
        </h1>
        <section className="app-tool-bar-utils-container">
          <menu className="app-tool-bar-utils-content">
            <HighContrast />
            <LanguageMenu />
            {isAuthenticated && perfils && (
              <div style={{ marginRigth: "0", marginLeft: "auto", color: "white" }}>
              <List component="nav" aria-label="Device settings">
                <ListItem
                  button
                  aria-haspopup="false"
                  aria-controls="lock-menu"
                  onClick={handleClickListItem}
                >
                  <AccountCircleIcon />
                  <ListItemText
                    primaryTypographyProps={{ style:{color: "white"} }}
                    secondaryTypographyProps={{ style:{color: "white"} }}
                    primary={perfils[this.state.selectedIndex].nome}
                    secondary={perfils[this.state.selectedIndex].identificacao}
                  />
                  <ExpandMoreOutlinedIcon />
                </ListItem>
              </List>
              <Menu
                className={classes.menu}
                id="perfil-menu"
                open={Boolean(this.state.anchorEl)}
                keepMounted
                onClose={handleClose}
                anchorEl={this.state.anchorEl}
              >
                {perfils.map((perfil, index) => (
                  <MenuItem
                    className={classes.menuItem}
                    key={perfil.id}
                    selected={index === this.state.selectedIndex}
                    onClick={(event) =>
                      handleMenuItemClick(event, index, perfil.id)
                    }
                  >
                    <ListItemText
                      primary={perfil.nome}
                      secondary={perfil.identificacao}
                    />
                  </MenuItem>
                ))}
              </Menu>
            </div>
            )}
          </menu>
        </section>
      </header>
    );
  }
}

AppToolbar.propTypes = {
  renderMenuBool: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  menuToggle: PropTypes.func.isRequired,
  perfils: PropTypes.array,
  isCadIncompleto: PropTypes.bool.isRequired,
};

AppToolbar.defaultProps = {
  renderMenuBool: true,
};

const styles = () => ({
  menuItem: {
    padding: "16px",
  },
  menu: {
    marginTop: "42px",
  },
});

function mapStateToProps(state) {
  return {
    isAuthenticated: userSelectors.isAuthenticated(state),
    userActive: userSelectors.getUserActive(state),
  };
}

const mapDispatchToProps = {
  changeUserActive: userActions.changeUserActive,
};

AppToolbar = withStyles(styles)(AppToolbar);
AppToolbar = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(AppToolbar);

export default AppToolbar;
